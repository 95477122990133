import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import CloseIcon from '@mui/icons-material/Close';

const RequestPopup = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    from_name: '',
    email: '',
    phone_number: '',
    message: '',
  });

  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_6mau3tk',
        'template_rankbvg',
        e.target,
        'qWqjWU735UEBI0kIb'
      )
      .then(
        (result) => {
          setMessage('Your request has been sent successfully!');
        },
        (error) => {
          setMessage(
            'There was an error sending your request. Please try again.'
          );
        }
      );

    setFormData({
      from_name: '',
      email: '',
      phone_number: '',
      message: '',
    });
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div
      className="  contactHolder"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100vh',
        width: '100%',
        backgroundColor: 'rgba(31, 31, 31, 0.995)',
        zIndex: 1000,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
      }}
    >
      <div
        style={{
          color: '#fff',
          position: 'absolute',
          top: '15%',
          right: '5%',
          zIndex: '100',
          cursor: 'pointer',
          fontSize: '1.5em',
        }}
        onClick={onClose}
      >
        <CloseIcon />
      </div>
      <div
        className="popupLinks address"
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-around',
          width: 'unset',
        }}
      >
        <div className="emailContent" style={{ marginLeft: '0', width: '50%' }}>
          <div className="text">General Inquiries and Collaborations:</div>
          <div className="email">nbinteriors@gmail.com</div>

          <div
            className="text"
            style={{
              marginBottom: '50px',
            }}
          >
            For each project the approach rests on a careful understanding of
            the space, or the site’s.
          </div>
        </div>
        <div className="inputHolder" style={{ width: '50%' }}>
          <form
            onSubmit={sendEmail}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              width: '100%',
            }}
          >
            <input
              type="text"
              name="from_name"
              placeholder="Your Name"
              value={formData.from_name}
              onChange={handleChange}
              required
              style={{ padding: '10px', fontSize: '16px' }}
            />
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              value={formData.email}
              onChange={handleChange}
              required
              style={{ padding: '10px', fontSize: '16px' }}
            />
            <input
              type="text"
              name="phone_number"
              placeholder="Your Phone"
              value={formData.phone_number}
              onChange={handleChange}
              required
              style={{ padding: '10px', fontSize: '16px' }}
            />
            <textarea
              name="message"
              placeholder="Your Request"
              value={formData.message}
              onChange={handleChange}
              required
              style={{ padding: '10px', fontSize: '16px', minHeight: '100px' }}
            ></textarea>
            {message && (
              <div
                style={{
                  margin: '0px auto',
                  color: '#fff',
                  textAlign: 'center',
                  fontSize: '12px',
                }}
              >
                {message}
              </div>
            )}
            <button type="submit" className="requestButton">
              Send Request
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RequestPopup;
