import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const ProjectsFilter = ({ onFilterChange }) => {
  const location = useLocation();
  const filterOptions = [
    { label: 'All', value: 'all' },
    { label: 'Bedrooms', value: 'bedrooms' },
    { label: 'Living Room', value: 'livingRoom' },
    { label: 'Dining Room', value: 'diningRoom' },
    { label: 'Toilets', value: 'toilets' },
    { label: 'Salons', value: 'salons' },
  ];

  const [selectedFilter, setSelectedFilter] = useState('all');
  const [initialFilterSet, setInitialFilterSet] = useState(false);

  // Effect to set the initial filter based on location state (only once)
  useEffect(() => {
    if (!initialFilterSet && location.state?.selectedFilter) {
      setSelectedFilter(location.state.selectedFilter);
      onFilterChange(location.state.selectedFilter);
      setInitialFilterSet(true); // Prevent future resets from location state
    }
  }, [location.state, onFilterChange, initialFilterSet]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location.state, initialFilterSet]);

  // Function to handle filter changes from user clicks
  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
    onFilterChange(filter);
  };

  return (
    <div
      className="projectsFilter"
      style={{
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        overflowX: 'scroll',
      }}
    >
      {filterOptions.map((option) => (
        <a
          style={{
            whiteSpace: 'nowrap',
            margin: '0px 30px 20px 0px',
            fontSize: '15px',
            color: '#828282',
            fontWeight: selectedFilter === option.value ? '700' : '500',
            cursor: 'pointer',
            color: selectedFilter === option.value ? '#000' : '#828282',
            transition: 'transform 0.3s ease', // Smooth scaling transition
            transform:
              selectedFilter === option.value ? 'scale(1.1)' : 'scale(1)',
          }}
          key={option.value}
          className={selectedFilter === option.value ? 'active' : ''}
          onClick={() => handleFilterChange(option.value)} // Handle user clicks to change filter
        >
          {option.label}
        </a>
      ))}
    </div>
  );
};

export default ProjectsFilter;
