import React, { useState, useEffect } from 'react';
import images from '../data/images';
import { useMediaQuery } from '@mui/material';

const CustomCarousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const isMobile = useMediaQuery('(max-width:960px)');

  const [carouselHeight, setCarouselHeight] = useState('100vh'); // Initial height

  // deacreases size as we scroll down
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollTop =
  //       window.pageYOffset || document.documentElement.scrollTop;
  //     const newHeight = Math.max(75, 100 - scrollTop / 10); // Adjust values as needed (min 75vh)
  //     setCarouselHeight(`${newHeight}vh`);
  //   };

  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentSlide((prev) => (prev === images.length - 1 ? 0 : prev + 1));
        setIsTransitioning(false);
      }, 1000); // Duration for the sliding effect
    }, 4000); // Stay for 4 seconds before sliding

    return () => clearInterval(slideInterval);
  }, [images.length]);

  const getSlideDirection = (index) => {
    return index % 2 === 0 ? 'slide-from-right' : 'slide-from-left';
  };

  const animateText = (text) => {
    return text.split('').map((letter, index) => (
      <span
        key={index}
        className="animated-letter"
        style={{ animationDelay: `${index * 0.1}s` }}
      >
        {letter === ' ' ? '\u00A0' : letter}{' '}
      </span>
    ));
  };

  const handleTitleClick = (index) => {
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentSlide(index);
      setIsTransitioning(false);
    }, 500); // Shorter duration for manual transitions
  };

  return (
    <header
      className="carousel-container"
      style={{ backgroundColor: 'black', height: '100vh' }}
    >
      <div
        className="animated-border-container"
        style={{ position: 'absolute', zIndex: 9 }}
      >
        <div
          className="animated-border-box"
          style={{ position: 'absolute', zIndex: 9 }}
        ></div>
      </div>
      {/* Carousel slides */}
      {images.map((slide, index) => (
        <div
          key={index}
          className={`carousel-slide ${
            index === currentSlide ? 'active' : ''
          } ${
            isTransitioning
              ? index === currentSlide
                ? getSlideDirection(index) + '-out'
                : index === (currentSlide + 1) % images.length
                ? getSlideDirection(currentSlide + 1) + '-in'
                : ''
              : ''
          }`}
        >
          <img src={slide.imgURL} alt={slide.imgAlt} className="carousel-img" />
          <div className="carousel-cover">
            <div className={`header-content slide-${currentSlide}`}>
              <div
                className={`floating-line line-animation-${currentSlide}`}
              ></div>
              <h2 className="animated-text">{slide.title}</h2>
            </div>
          </div>
        </div>
      ))}

      {/* Right sidebar with image titles */}
      <div
        className="carousel-titles"
        style={{
          position: 'absolute',
          bottom: '3vh',
          right: isMobile ? '0' : '4vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: isMobile ? '100%' : '50%',
          padding: isMobile ? '0px 15px' : '0',
          zIndex: '11',
        }}
      >
        <ul
          style={{
            display: 'flex',
            flexDirection: 'row',
            color: 'rgb(242 242 242)',
            textDecoration: 'none',
            listStyleType: 'none',
            justifyContent: 'space-around',
            gap: '5vw',
            padding: '0',
          }}
        >
          {images.map((slide, index) => (
            <div key={index}>
              <li
                style={{
                  maxWidth: '170px',
                  textAlign: isMobile ? 'center' : 'start',
                  opacity: index === currentSlide ? '1' : '0.5',
                  cursor: 'pointer', // Add pointer cursor to indicate it's clickable
                  fontSize: isMobile ? '12px' : '16px',
                }}
                className={`carousel-title ${
                  index === currentSlide ? 'active-title' : ''
                }`}
                onClick={() => handleTitleClick(index)} // Handle title click
              >
                {slide.title}
              </li>
            </div>
          ))}
        </ul>
      </div>
    </header>
  );
};

export default CustomCarousel;
