const images = [
  {
    imgURL: 'images/project1-1.jpeg',
    imgAlt: 'Luxury Salon Interior Design',
    title: 'Luxury Salon Interior Design',
    link: '/luxury-salon-design',
    count: '01',
  },
  {
    imgURL: 'images/image2.jpeg',
    imgAlt: 'Modern Bedroom Design',
    title: 'Modern Bedroom Renovation',
    link: '/modern-bedroom-design',
    count: '02',
  },
  {
    imgURL: 'images/image7.jpeg',
    imgAlt: 'Elegant Dining Room',
    title: 'Elegant Dining Room Setup',
    link: '/elegant-dining-room',
    count: '03',
  },
];

export default images;
